import Link from "next/link";
import React, { forwardRef } from "react";

function FooterLink(
  {
    children,
    ...props
  }: {
    children: React.ReactNode;
    href: string;
  } & React.HTMLAttributes<HTMLAnchorElement>,
  ref: any
) {
  const LinkElement = props.href?.startsWith("http") ? "a" : Link;

  return (
    <li>
      <LinkElement
        className="inline-flex text-sm font-normal cursor-pointer text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"
        ref={ref}
        {...props}
      >
        {children}
      </LinkElement>
    </li>
  );
}

export default forwardRef(FooterLink);
